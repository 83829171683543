@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

:root {
    --blue200: rgb(191 219 254);
    --blue300: rgb(147 197 253);
    --blue400: rgb(96 165 250);
    --blue500: rgb(59 130 246);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 15px;
}

*::-webkit-scrollbar-track {
    background: var(--blue200);
    border-radius: 5px;
    margin-outside: 5px;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--blue400);
    border-radius: 14px;
    border: 3px solid var(--blue200);
}

.scroller {
    max-width: 600px;
}

.scroller__inner {
    padding-block: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.scroller {
    max-width: max-content;
}

.scroller__inner {
    padding-block: 1rem;
    display: flex;
    gap: 1rem;
}

.scroller[data-animated="true"] {
    overflow: hidden;
    mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
}

.scroller[data-animated="true"] .scroller__inner {
    width: max-content;
    flex-wrap: nowrap;
    animation: scroll var(--_animation-duration, 40s)
    var(--_animation-direction, forwards) linear infinite;
}

.scroller[data-direction="right"] {
    --_animation-direction: reverse;
}

.scroller[data-direction="left"] {
    --_animation-direction: forwards;
}

.scroller[data-speed="fast"] {
    --_animation-duration: 20s;
}

.scroller[data-speed="slow"] {
    --_animation-duration: 60s;
}

@keyframes scroll {
    to {
        transform: translate(calc(-50% - 0.5rem));
    }
}


/* for testing purposed to ensure the animation lined up correctly */
.test {
    background: red !important;
}

